import { faFlag, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./LanguageSelector.sass";


const LanguageSelector = () => {

    const [dropdownOpened, setDropdownOpened] = useState(false);
    const { i18n } = useTranslation();

    const changeLanguage = event => {
        let locale = event.target.getAttribute("data-locale");
        i18n.changeLanguage(locale);
        setDropdownOpened(false);
    }

    return (
        <div className="languageSelector">
            <button onClick={ () => setDropdownOpened(!dropdownOpened) }  className={dropdownOpened ? "opened" : ""}>
                <FontAwesomeIcon icon={faFlag} /> Language / Kieli / Språk / Język
            </button>
            {dropdownOpened ? (
                <ul>
                    <li><button data-locale="en" onClick={e => changeLanguage(e)}>English</button></li>
                    <li><button data-locale="fi" onClick={e => changeLanguage(e)}>Suomen</button></li>
                    <li><button data-locale="se" onClick={e => changeLanguage(e)}>Svenska</button></li>
                    <li><button data-locale="pl" onClick={e => changeLanguage(e)}>Polski</button></li>
                    <li><button onClick={() => setDropdownOpened(false)}><FontAwesomeIcon icon={faX} /></button></li>
                </ul>
            ) : null}
        </div>
    );

}

export default LanguageSelector;
