import { useCallback, useEffect, useState } from "react";
import "./ThemeChanger.sass";

const ThemeChanger = () => {

    const prefersDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [themeType, setThemeType] = useState(prefersDarkTheme);

    const changeTheme = useCallback(() => {
        setThemeType(!themeType);
        document.body.className = "";
        document.body.classList.add(!themeType ? "dark" : "light");
    }, [themeType, setThemeType]);


    useEffect(() => {
        if (prefersDarkTheme) {
            document.body.className = "";
            document.body.classList.add(themeType ? "dark" : "light");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="themeChanger">
            <input type="checkbox" className="themeCheckbox" name="theme" id="theme" checked={themeType} onChange={() => changeTheme()} />
            <label className="themeLabel" htmlFor="theme">
                <span className="themeInner"></span>
                <span className="themeSwitch"></span>
            </label>
        </div>
    )

}

export default ThemeChanger;
