import i18n from "i18next";
import { initReactI18next, useTranslation, Trans } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import "./App.sass";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCat, faEnvelope, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import ThemeChanger from "../ThemeChanger/ThemeChanger";
import { faGithub, faGithubAlt, faLinkedin } from "@fortawesome/free-brands-svg-icons";

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: false
    },
    ns: ["translation"],
    defaultNs: "translation",
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locale/{{lng}}/{{ns}}.json"
    }
  });

const App = () => {

  const {t} = useTranslation();

  return (
    <div className="app">
      <div className="settings">
        <ThemeChanger />
        <LanguageSelector />
      </div>
      <header>
        <h1>{t("greeting")} <FontAwesomeIcon icon={faCat} /></h1>
        <h2>{t("description")}</h2>
      </header>
      <div className="contents">
        <section className="about">
          <h3>{t("about-header")}</h3>
          <p>
            <Trans i18nKey="about-contents"
                   values={{ age: 23, experienceYears: 2, locationA: "Katowice, Poland", locationB: "Helsinki, Finland", locationBFull: "Helsinki Metropolitan Area" }}></Trans>
          </p>
        </section>
        <section className="projects">
          <h3>{t("projects-header")}</h3>

          <ul>
            <li>
              <h4>sticker&ndash;cat</h4>
              <h5>( 2024 )</h5>

              <p>{t("projects-stickercat")}</p>
            </li>
            <li>
              <h4>#lpfm</h4>
              <h5>( 2019 &mdash; {t("to-now")} )</h5>

              <p><Trans i18nKey="projects-lpfm"></Trans></p>
              <FontAwesomeIcon icon={faGithubAlt} /> <a href="https://github.com/laspegas-dot-us">{t("visit-github-org")}</a>
            </li>
            <li>
              <h4>cd.fm</h4>
              <h5>( 2015 &mdash; 2018 )</h5>

              <p>{t("projects-cdfm")}</p>
            </li>
          </ul>
        </section>
        <section className="links">
          <h3>{t("contact-header")}</h3>

          <ul>
            <li><FontAwesomeIcon icon={faEnvelope} /><a href="mailto:jc@cichocky.eu">jc@cichocky.eu</a> ({t("email-preferred")})</li>
            <li><FontAwesomeIcon icon={faEnvelope} /><a href="mailto:cichojar@gmail.com">cichojar@gmail.com</a></li>
            <li><FontAwesomeIcon icon={faPhoneSquare} /><span className="flagEmoji">🇵🇱</span> +48 798 530 153</li>
            <li><FontAwesomeIcon icon={faPhoneSquare} /><span className="flagEmoji">🇫🇮</span> +358 ({t("phone-soon-available")})</li>
            <br />
            <li><FontAwesomeIcon icon={faGithub} /><a href="https://github.com/noskla">@noskla</a></li>
            <li><FontAwesomeIcon icon={faLinkedin} /><a href="https://www.linkedin.com/in/cichocki-jar">/in/cichocki-jar</a></li>
          </ul>
        </section>
      </div>
    </div>
  )

}

export default App;
